'use client'

import { Button } from '@pattern-library/src/basicUI/Button'
import { Divider } from '@pattern-library/src/basicUI/Divider'
import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Skeleton } from '@pattern-library/src/basicUI/Skeleton'
import { Text } from '@pattern-library/src/basicUI/Text'
import { TextCube } from '@pattern-library/src/basicUI/TextCube'
import { Textfield } from '@pattern-library/src/basicUI/Textfield'
import { AuthenticatedCenteredTemplateWithData } from 'app/(authenticated)/[workspace]/[brand]/[siteId]/_components/AuthenticatedCenteredTemplateWithData'
import { WaitingWithData } from 'app/_components/WaitingWithData'
import { useRouter } from 'next-nprogress-bar'
import Link from 'next/link'
import { useState } from 'react'
import { useListUserWorkspacesQuery } from 'src/graphql/operations'
import { useGetAllAppContext } from 'src/hooks/useGetAllAppContext'
import { authenticatedPaths } from 'src/utils/route-utils'

import { buildAuthenticatedRoute } from '../../src/utils/url-utils'

const MINIMAL_AMOUNT_OF_WORKSPACES_BEFORE_SHOWING_SEARCH = 3

export default function Client() {
  const [searchTerm, setSearchTerm] = useState('')
  const [cubeIdLoading, setCubeIdLoading] = useState('')
  const { user } = useGetAllAppContext()
  const router = useRouter()

  const { loading, error, data } = useListUserWorkspacesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      username: user?.email,
    },
  })

  const numberOfWorkspaces = data?.listUserWorkspaces?.length || 0

  const filteredWorkspaces =
    numberOfWorkspaces > MINIMAL_AMOUNT_OF_WORKSPACES_BEFORE_SHOWING_SEARCH
      ? data?.listUserWorkspaces.filter(brand => {
          return brand.name.toLowerCase().includes(searchTerm.toLowerCase())
        })
      : data?.listUserWorkspaces

  if (loading) return <WaitingWithData message="loading workspaces" />

  if (error) {
    console.error('DashboardWorkspaces error:', error)
    return <WaitingWithData message="Error loading workspaces" />
  }

  return (
    <AuthenticatedCenteredTemplateWithData>
      <Text variant="h2" css={{ color: '$gs12', mb: '$10' }} oll={true}>
        Workspaces
      </Text>
      {loading && numberOfWorkspaces === 0 ? (
        <>
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
          <Skeleton height="h3" verticalSpacing="medium" />
        </>
      ) : !loading && numberOfWorkspaces === 0 ? (
        <Flex justify={'center'} gap={7} direction={'column'}>
          <Text css={{ textWrap: 'pretty' }}>
            Welcome to PubGen.ai! We're excited to have you on the platform. Your workspace is where you house all your
            publications. Let's get started!
          </Text>
          <div>
            <Button
              label="Go to workspace creation"
              onClick={() => router.push(authenticatedPaths.newWorkspace)}
              css={{ margin: '0 auto' }}
            />
          </div>
        </Flex>
      ) : (
        <>
          {numberOfWorkspaces > MINIMAL_AMOUNT_OF_WORKSPACES_BEFORE_SHOWING_SEARCH && (
            <Textfield
              placeholder={`Search for a workspace`}
              css={{
                mb: '$5',
                width: 'min(100%, 400px)',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              value={searchTerm}
              name="search"
              onChange={e => setSearchTerm(e.target.value)}
              autoFocus={true}
            />
          )}
          <Flex
            gap={8}
            direction="column"
            css={{
              p: '$5',
              pr: '$10',
            }}>
            {filteredWorkspaces?.map(el => (
              <TextCube
                state={cubeIdLoading === el.id ? 'loading' : 'default'}
                title={el.name}
                key={el.id}
                onClick={() => {
                  setCubeIdLoading(el.subdomain)
                  router.push(buildAuthenticatedRoute({ workspace: el.subdomain }))
                }}
              />
            ))}
            {numberOfWorkspaces > MINIMAL_AMOUNT_OF_WORKSPACES_BEFORE_SHOWING_SEARCH &&
              filteredWorkspaces?.length === 0 && <Text>No workspace found.</Text>}
          </Flex>
          <>
            <Divider
              css={{
                mt: '$8',
                mb: '$5',
              }}
            />
            <Link href={authenticatedPaths.newWorkspace}>
              <Text isLink={true} css={{ color: '$lin' }}>
                New workspace
              </Text>
            </Link>
          </>
        </>
      )}
    </AuthenticatedCenteredTemplateWithData>
  )
}

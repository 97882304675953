import type { BrandGroupType, MenuItem, Site, WorkspaceGroup } from '@models/types'
import type { WysiwygElement } from '@models/wysiwyg-types'

import { PUBLICATION_INTERNAL_PAGE_SLUGS } from './constants'

export const compareUserAuthorizations = (
  authorizerWorkspaceGroups: WorkspaceGroup[],
  authorizerBrandGroups: BrandGroupType[],
  workspaceGroups: WorkspaceGroup[],
  brandGroups: BrandGroupType[],
): boolean => {
  if (authorizerWorkspaceGroups.includes('Admin')) {
    //admin user has all rights
    return true // after this user is not admin
  }
  // check workspace permissions
  for (const group of workspaceGroups) {
    // check that for each permission given, the user has the right to give it
    if (group === 'Admin' || group === 'Accounting') {
      return false // cannot give admin or Accounting role if you are not admin
    }
    if (group === 'Builder' || group === 'Creator' || group === 'Guest') {
      // Giving a Role on the workspace level is only possible if you are a builder on workspace level
      if (!authorizerWorkspaceGroups.includes('Builder')) {
        return false
      }
    }
  }
  // check brand permissions
  for (const newBrandGroup of brandGroups) {
    const brandGroup = authorizerBrandGroups.find(b => b.brandId === newBrandGroup.brandId)
    for (const group of newBrandGroup.groups) {
      if (group === 'Builder' || group === 'Creator' || group === 'Guest') {
        // trying to give access to a brand
        if (
          !(
            (
              authorizerWorkspaceGroups.includes('Builder') || // if user is neither builder on workspace level
              (brandGroup && brandGroup.groups.includes('Builder'))
            ) // or builder on brand level
          )
        ) {
          return false
        }
      }
    }
  }
  return true
}

export const ERROR_TYPES = {
  WrongField: 'WrongField',
  LeadGeneration: 'LeadGeneration',
  WrongIdPrefix: 'WrongIdPrefix',
  WrongSlugFormat: 'WrongSlugFormat',
  NoToken: 'NoToken',
  NotFound: 'NotFound',
  Unauthorized: 'Unauthorized',
  HeadlineGeneration: 'HeadlineGeneration',
  NoArticle: 'NoArticle',
  BlockNotFound: 'BlockNotFound',
  BlocksNotFound: 'BlocksNotFound',
  PageNotFound: 'PageNotFound',
  BrandNotFound: 'BrandNotFound',
  DomainFormatError: 'DomainFormatError',
  DomainUnavailable: 'DomainUnavailable',
  DomainNotConnected: 'DomainNotConnected',
  NoDomainConnected: 'NoDomainConnected',
  DomainNotFound: 'DomainNotFound',
  NewsLetterNotFound: 'NewsLetterNotFound',
  SlugAlreadyExists: 'SlugAlreadyExists',
  MissingArgument: 'MissingArgument',
  StripeNotConnected: 'StripeNotConnected',
  SubdomainWrongFormat: 'SubdomainWrongFormat',
  WorkspaceNotFound: 'WorkspaceNotFound',
  IdNotAvailable: 'IdNotAvailable',
  LambdaError: 'LambdaError',
  UserNotCreated: 'UserNotCreated',
  BlocksFromMultipleSite: 'BlocksFromMultipleSite',
  UserCreationError: 'UserCreationError',
  DomainAlreadyConnected: 'DomainAlreadyConnected',
  WorkspaceHasActiveBrands: 'WorkspaceHasActiveBrands',
  UserAlreadyExists: 'UserAlreadyExists',
  WrongSiteId: 'WrongSiteId',
  InvalidSiteId: 'InvalidSiteId',
  NewsLetterAlreadyExists: 'NewsLetterAlreadyExists',
  StripeCheckoutError: 'StripeCheckoutError',
  NoSubscriptionsToExport: 'NoSubscriptionsToExport',
  PropaySubscriptionsError: 'PropaySubscriptionsError',
  PropaySubscriptionPlansError: 'PropaySubscriptionPlansError',
  PropayError: 'PropayError',
  ProtectpayError: 'ProtectpayError',
  ExportMultipleBrandError: 'ExportMultipleBrandError',
  ExportNonArticlePageError: 'ExportNonArticlePageError',
  CreateSubscriptionError: 'CreateSubscriptionError',
  CancelSubscriptionError: 'CancelSubscriptionError',
  AlreadySubscribed: 'AlreadySubscribed',
  ListSubscriptionError: 'ListSubscriptionError',
  ExportPropaySubscribers: 'ExportPropaySubscribers',
  ExportingData: 'ExportingData',
  BadRequest: 'BadRequest',
} as const

export type ERROR_TYPES = keyof typeof ERROR_TYPES

export const getFriendUserErrorMessageFromErrorType = (errorType: ERROR_TYPES): string => {
  switch (errorType) {
    case 'BlocksFromMultipleSite':
    case 'WrongField':
    case 'LeadGeneration':
    case 'WrongIdPrefix':
    case 'NoToken':
    case 'NotFound':
    case 'BlockNotFound':
    case 'BlocksNotFound':
    case 'PageNotFound':
    case 'BrandNotFound':
    case 'MissingArgument':
    case 'IdNotAvailable':
    case 'LambdaError':
    case 'PropaySubscriptionsError':
    case 'PropaySubscriptionPlansError':
    case 'PropayError':
      return 'An error occurred, please try again later.'
    case 'ExportPropaySubscribers':
      return 'An error occurred while exporting the subscribers. Please try again later.'
    case 'AlreadySubscribed':
      return 'Subscriber with this email already has for this publication.'
    case 'CreateSubscriptionError':
      return 'An error occurred while processing the subscription. Please try again later.'
    case 'CancelSubscriptionError':
      return 'An error occurred while canceling the subscription. Please try again later.'
    case 'ListSubscriptionError':
      return 'An error occurred while listing the subscriptions. Please try again later.'
    case 'ProtectpayError':
      return 'An error occurred while processing the payment. Please try again later.'
    case 'Unauthorized':
      return 'You are not authorized to perform this action.'
    case 'HeadlineGeneration':
      return 'An error occurred while generating the headline, please try again later.'
    case 'NoArticle':
      return 'The article could not be downloded. Please try again later.'
    case 'DomainFormatError':
      return 'The domain format is not correct. Please use a valid domain format.'
    case 'DomainUnavailable':
      return 'The domain is already in use. Please use a different domain.'
    case 'DomainNotConnected':
      return 'The domain is not connected to the site. Please connect the domain to the site.'
    case 'NoDomainConnected':
      return 'No domain is connected to the site. Please connect a domain to the site.'
    case 'NewsLetterNotFound':
      return 'The newsletter could not be found. Please try again later.'
    case 'WrongSlugFormat':
      return 'The slug format is incorrect. Please use a slug that starts and ends with a forward slash.'
    case 'SlugAlreadyExists':
      return 'A page with this slug already exists. If you are creating a new page, please change the page title, otherwise please choose a different slug.'
    case 'StripeNotConnected':
      return 'The Stripe account is not connected. Please connect the Stripe account to the site.'
    case 'SubdomainWrongFormat':
      return 'The subdomain format is incorrect. Please use a valid subdomain format.'
    case 'DomainNotFound':
      return 'The domain could not be found. Please try again later.'
    case 'WorkspaceNotFound':
      return 'The workspace could not be found. Please try again later.'
    case 'UserNotCreated':
      return 'The user could not be created. Please try again later.'
    case 'WorkspaceHasActiveBrands':
      return 'You cannot delete a workspace that has active brands, please delete all brands before deleting the workspace'
    case 'DomainAlreadyConnected':
      return 'This site already has a connected domain. Please disconnect the current domain before connecting a new one.'
    case 'UserAlreadyExists':
      return 'A user with that email address already exists in the wprkspace. Please use a different email address.'
    case 'NewsLetterAlreadyExists':
      return 'A newsletter with that name already exists. Please choose a different name.'
    case 'StripeCheckoutError':
      return 'An error occurred while processing the payment. Please try again later.'
    case 'NoSubscriptionsToExport':
      return 'There are no subscriptions to export for this period'
    case 'InvalidSiteId':
      return 'The site id is invalid'
    case 'ExportMultipleBrandError':
      return 'All pages should belong to the same brand'
    case 'ExportNonArticlePageError':
      return 'Only article pages can be exported'
    case 'ExportingData':
      return 'Data will take a few minutes to export. Please check your email for the download link.'
    default:
      return `An error occurred, please try again later. (Error Code: ${errorType}) `
  }
}

export type MenuItemsOptions = 'externalUrl' | 'article' | 'section' | 'tag' | 'author' | 'internalPage' | 'menu'

export const getDropdownChoiceFromMenuItem = (menuItem: MenuItem): MenuItemsOptions | undefined => {
  const { url, type } = menuItem

  if (type === 'MENU') return 'menu'

  if (!url) return undefined

  if (url.startsWith('/section')) return 'section'

  if (url.startsWith('/tag')) return 'tag'

  if (url.startsWith('/writer')) return 'author'

  if (Object.values(PUBLICATION_INTERNAL_PAGE_SLUGS).includes(url)) return 'internalPage'

  if (url.startsWith('/')) return 'article'

  // There are no validations for external urls
  return 'externalUrl'
}

export const checkHeaderAdUnitIsUnique = ({
  scriptsToCompare: { script1, script2, script3, script4 },
  scriptValue,
}: {
  scriptsToCompare: {
    script1?: string
    script2?: string
    script3?: string
    script4?: string
  }
  scriptValue: string
}): boolean => {
  const { adUnitCode: adUnitScript1 } = getTagInfoFromDocumentHeaderScript(script1 || '')
  const { adUnitCode: adUnitScript2 } = getTagInfoFromDocumentHeaderScript(script2 || '')
  const { adUnitCode: adUnitScript3 } = getTagInfoFromDocumentHeaderScript(script3 || '')
  const { adUnitCode: adUnitScript4 } = getTagInfoFromDocumentHeaderScript(script4 || '')
  const { adUnitCode: adUnitScriptValue } = getTagInfoFromDocumentHeaderScript(scriptValue)

  return (adUnitScript1 && adUnitScript1 === adUnitScriptValue) ||
    (adUnitScript2 && adUnitScript2 === adUnitScriptValue) ||
    (adUnitScript3 && adUnitScript3 === adUnitScriptValue) ||
    (adUnitScript4 && adUnitScript4 === adUnitScriptValue)
    ? false
    : true
}

export const getFontFormatFromFile = (file: string): string => {
  const fontFileType = file.split('.').pop()
  let fontFormat = ''
  switch (fontFileType) {
    case 'woff':
      fontFormat = 'woff'
      break
    case 'woff2':
      fontFormat = 'woff2'
      break
    case 'ttf':
      fontFormat = 'trueType'
      break
    case 'otf':
      fontFormat = 'opentype'
      break
    case 'eot':
      fontFormat = 'embedded-opentype'
      break
    default:
      console.warn('font file extension not recognized', fontFileType, file)
      break
  }
  return fontFormat
}

export const wysiwygElementHasContent = (element: WysiwygElement[] | undefined): boolean => {
  if (!element) return false
  // recursively check if element has text content
  const hasContent = (el: WysiwygElement): boolean => {
    if ('text' in el) {
      return !!el.text
    }
    if ('children' in el) {
      return el.children.some(hasContent)
    }
    return false
  }
  if (element.some(hasContent)) {
    return true
  }

  return false
}

export type QueryParamsType = keyof typeof queryParams

export const queryParams = {
  article: {
    query_by:
      'title, slug, authors.name, tags.name, section.name, byline, summary, image.caption, image.credit, bodyText,',
    query_by_weights: '10, 10, 5, 5, 5, 5, 2, 1, 1, 1',
    exclude_fields: '_vectors, bodyText',
    highlight_fields: 'none',
    highlight_full_fields: 'none',
  },
  section: {
    query_by: 'name, slug',
    exclude_fields: '_vectors',
    highlight_fields: 'none',
    highlight_full_fields: 'none',
  },
  tag: {
    query_by: 'name, slug',
    exclude_fields: '_vectors',
    highlight_fields: 'none',
    highlight_full_fields: 'none',
  },
  author: {
    query_by: 'name, slug, bio, position, email',
    exclude_fields: '_vectors',
    highlight_fields: 'none',
    highlight_full_fields: 'none',
  },
  all: {
    query_by:
      'title, slug, name, authors.name, tags.name, section.name, byline, summary, image.caption, image.credit, bodyText,',
    query_by_weights: '10, 10, 10, 5, 5, 5, 5, 2, 1, 1, 1',
    exclude_fields: '_vectors, bodyText',
    highlight_fields: 'none',
    highlight_full_fields: 'none',
  },
}

export const fromUnixToUTC = (timestamp: number | undefined | null): string => {
  // if timestampt is a string and cannot be parsed to a number, return undefined
  if (timestamp === undefined || timestamp === null) {
    return ''
  }
  if (isNaN(timestamp)) {
    console.warn('fromUnixToUTC: timestamp is not a number:', timestamp)
    return ''
  }
  return new Date(timestamp * 1000).toISOString()
}

export const toUnixTimestamp = (date: string | null | undefined): number | undefined | null => {
  if (date === undefined) {
    return undefined
  }
  if (date === null) {
    return null
  }
  return parseInt((new Date(date).getTime() / 1000).toFixed(0))
}

export const getSiteUrl = (site: { domainName?: string | null; domainStatus?: string | null; id: string }): string =>
  `https://${getSiteHost(site)}`

export const getSiteHost = (site: { domainName?: string | null; domainStatus?: string | null; id: string }): string => {
  if (site.domainName && site.domainStatus === 'Active') {
    return site.domainName
  } else {
    return `${site.id}.${process.env.PUBLIC_SITES_DOMAIN}`
  }
}

export const getSiteKey = (site: Site): string => {
  if (site.domainName && site.domainStatus === 'Active') {
    return site.domainName
  } else {
    return site.id
  }
}

export const slugify = (str: string, encloseWithSlash: boolean = true): string => {
  str = str
    .trim()
    .toLowerCase()
    .replace(/[^\w \/-]+/g, '')
    .replace(/ +/g, '-')
    .replace(/\/+/g, '-')

  if (encloseWithSlash) {
    if (!str.startsWith('/')) {
      str = `/${str}`
    }
    if (!str.endsWith('/')) {
      str = `${str}/`
    }
  } else {
    if (str.startsWith('/')) {
      str = str.substr(1)
    }
    if (str.endsWith('/')) {
      str = str.substr(0, str.length - 1)
    }
  }
  return str
}

export const createArticleSlug = ({
  articleTitle,
  sectionName,
  id,
}: {
  articleTitle: string
  sectionName?: string | null
  id: string | null
}): string => {
  const slugifiedSection = slugify(sectionName || 'stories', false)
  const slugifiedTitle = slugify(articleTitle, false)
  const slugifiedId = id ? `-${slugify(id, false)}` : ''

  return `/${slugifiedSection}/${slugifiedTitle}${slugifiedId}/`
}

export const createTagSlug = ({ tag }: { tag: string }) => `${PUBLICATION_INTERNAL_PAGE_SLUGS.tags}${slugify(tag)}`

export const createAuthorSlug = ({ author }: { author: string }) =>
  `${PUBLICATION_INTERNAL_PAGE_SLUGS.writers}${slugify(author)}`

export const createSectionSlug = ({ section }: { section: string }) =>
  `${PUBLICATION_INTERNAL_PAGE_SLUGS.sections}${slugify(section)}`

export const formatTitleForSeo_Article = (articleTitle: string) =>
  articleTitle.length > 70 ? `${articleTitle.slice(0, 70)}...` : articleTitle

export const formatDescriptionForSeo_Article = (articleSummary: string) =>
  articleSummary.length > 320 ? `${articleSummary.slice(0, 320)}...` : articleSummary

export const formatTitleForSeo_Section = ({ sectionName, siteName }: { sectionName: string; siteName: string }) =>
  `The latest ${sectionName} News, Stories, and Articles | ${siteName}.`

export const formatDescriptionForSeo_Section = ({ sectionName, siteName }: { sectionName: string; siteName: string }) =>
  `The latest ${sectionName} News, Stories, and Articles | ${siteName}. Stay informed with in-depth coverage of ${sectionName} and more. Find compelling stories, insightful analysis, and breaking news in one convenient location.`

export const formatTitleForSeo_Tag = ({ tagName, siteName }: { tagName: string; siteName: string }) =>
  `Explore ${tagName} News, Stories, and Articles | ${siteName}.`

export const formatDescriptionForSeo_Tag = ({ tagName, siteName }: { tagName: string; siteName: string }) =>
  `Discover a curated collection of articles related to ${tagName} on ${siteName}. Stay informed with the latest news, analysis, and insights on [Tag Topic]. Explore diverse perspectives and in-depth coverage of ${tagName} in one convenient location.`

export const formatTitleForSeo_Author = ({ authorName, siteName }: { authorName: string; siteName: string }) =>
  `Articles, stories, news, by ${authorName} | ${siteName}.`

export const formatDescriptionForSeo_Author = ({ authorName, siteName }: { authorName: string; siteName: string }) =>
  `Explore the insightful articles and contributions by ${authorName} on ${siteName}. Discover compelling stories, expert analysis, and thought-provoking commentary from this accomplished writer. Learn more about ${authorName}'s background and expertise in their biography.`

export const formatImageForSeo_author = (key: string | undefined | null) =>
  key ? `https://${process.env.NEXT_PUBLIC_PUBLIC_ASSET}/${key}` : ''

export const formatTitleForHomepage = ({ siteName, titleTag }: { siteName: string; titleTag?: string }) => {
  return titleTag ? `${siteName} | ${titleTag}` : siteName
}

export const formatDescriptionForHomepage = ({ siteName, description }: { siteName: string; description?: string }) => {
  return description ? `${description} | ${siteName}` : `The latest news, stories, and articles from ${siteName}.`
}

export const serializeSlateToText = (nodes: WysiwygElement[]): string => {
  if (!nodes || !nodes.length || !Array.isArray(nodes)) {
    return ''
  }
  return nodes
    .map(node => {
      if ('text' in node && node.text) {
        return node.text
      }
      if ('children' in node && node.children) {
        return serializeSlateToText(node.children)
      }
      return ''
    })
    .join(' ')
}

type NullToUndefined<T extends Record<string, unknown>> = {
  [K in keyof T]: Exclude<T[K], null>
}

export const makeNullPropertyUndefined = <T extends Record<string, unknown>>(obj: T): NullToUndefined<T> => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null) {
      delete obj[key]
    }
  })
  return obj as any
}

export const getTagInfoFromDocumentHeaderScript = (
  script: string,
): {
  adUnitCode?: string
  sizes?: [number, number][]
  minWidth?: number
  minHeight?: number
  // maxWidth?: number
  // maxHeight?: number
  allTagInfoIsPresent: boolean
} => {
  // This is an example of the script that we are parsing:
  // <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
  // <script>
  //   window.googletag = window.googletag || {cmd: []};
  //   googletag.cmd.push(function() {
  //     googletag.defineSlot('/1094602/arr_app', [[700, 1200], [728, 90], [1200, 700], [800, 500], [700, 700]], 'div-gpt-ad-1713333077152-0').addService(googletag.pubads());
  //     googletag.pubads().enableSingleRequest();
  //     googletag.enableServices();
  //   });
  // </script>
  // The adUnitCode is "/1094602/arr_app". It's the first parameter of the defineSlot function.
  // The sizes are the second parameter of the defineSlot function. The sizes are an array of arrays. Each array has two numbers: the width and the height of the ad.

  // Should return '/1094602/arr_app', [[700, 1200], [728, 90], [1200, 700], [800, 500], [700, 700]], 'div-gpt-ad-1713333077152-0'

  let sizes: [number, number][] | undefined
  let adUnitCode: string | undefined
  let minWidth: number | undefined
  let minHeight: number | undefined
  // let maxWidth: number | undefined
  // let maxHeight: number | undefined
  const defineSlotFunctionMatch = script.match(/googletag\.defineSlot\('([^]+)'/)

  if (defineSlotFunctionMatch !== null) {
    const [arg1, arg2, arg3] = defineSlotFunctionMatch[1].split("'")
    adUnitCode = arg1
    // arg2 will be like: ", [[700, 1200], [728, 90], [1200, 700], [800, 500], [700, 700]], "
    // we need to get an array of numbers from it like: [[700, 1200], [728, 90], [1200, 700], [800, 500], [700, 700]]
    const allArrayOfNumbersInArg2 = arg2.match(/\[(\d+, \d+)\]/g)
    if (allArrayOfNumbersInArg2) {
      const arrayOfNumberForSizes = allArrayOfNumbersInArg2.map(arrayOfNumbers =>
        arrayOfNumbers
          .match(/(\d+), (\d+)/)
          ?.slice(1, 3)
          .map(n => parseInt(n)),
      )
      if (arrayOfNumberForSizes.map(size => size && size[0] && size[1])) {
        sizes = arrayOfNumberForSizes as [number, number][]
        minWidth = sizes.reduce(
          (acc, [width]): number | undefined => {
            if (!acc) return width
            return width < acc ? width : acc
          },
          undefined as undefined | number,
        )
        minHeight = sizes.reduce(
          (acc, [, height]): number | undefined => {
            if (!acc) return height
            return height < acc ? height : acc
          },
          undefined as undefined | number,
        )
        // maxWidth = sizes.reduce(
        //   (acc, [width]): number | undefined => {
        //     if (!acc) return width
        //     return width > acc ? width : acc
        //   },
        //   undefined as undefined | number,
        // )
        // maxHeight = sizes.reduce(
        //   (acc, [, height]): number | undefined => {
        //     if (!acc) return height
        //     return height > acc ? height : acc
        //   },
        //   undefined as undefined | number,
        // )
      }
    }
  }

  return {
    adUnitCode,
    sizes,
    minWidth,
    minHeight,
    // maxWidth,
    // maxHeight,
    allTagInfoIsPresent: !!(adUnitCode && sizes),
  }
}

/**
 *
 * default items for toolbar menu to select different auth-states while mocking
 * https://storybook.js.org/addons/@tomfreudenberg/next-auth-mock?ref=storybookblog.ghost.io
 *
 */
export const mockAuthStates = {
  unknown: {
    title: 'session unknown',
    session: null,
  },
  loading: {
    title: 'session loading',
    session: {
      data: null,
      status: 'loading',
    },
  },
  adminUnAuthed: {
    title: 'admin not authenticated',
    session: {
      data: {
        id: 1,
        login: 'admin',
        role: 'admin',
        roles: ['admin', 'user'],
        username: 'Administrator',
        email: 'admin@local',
      },
      status: 'unauthenticated',
    },
  },
  adminAuthed: {
    title: 'admin authenticated',
    session: {
      data: {
        id: 1,
        login: 'admin',
        role: 'admin',
        roles: ['admin', 'user'],
        username: 'Administrator',
        email: 'admin@local',
      },
      status: 'authenticated',
    },
  },
  userUnAuthed: {
    title: 'user not authenticated',
    session: {
      data: {
        id: 999,
        login: 'user',
        role: 'user',
        roles: ['user'],
        username: 'User',
        email: 'user@local',
      },
      status: 'unauthenticated',
    },
  },
  userAuthed: {
    title: 'user authenticated',
    session: {
      data: {
        id: 999,
        login: 'user',
        role: 'user',
        roles: ['user'],
        username: 'User',
        email: 'user@local',
      },
      status: 'authenticated',
    },
  },
}

export const getProtectpayAuthorizationHeader = ({
  authToken,
  billerAccountId,
}: {
  authToken: string
  billerAccountId: string
}): string => {
  const protectPayValueToConvertToASCIIByteArray = `${billerAccountId}:${authToken}`
  const protectpayASCIIByteArray = new TextEncoder().encode(protectPayValueToConvertToASCIIByteArray)
  const protectpayBase64Encoded = btoa(String.fromCharCode(...protectpayASCIIByteArray))
  const protectpayAuthorizationHeader = `Basic ${protectpayBase64Encoded}`
  return protectpayAuthorizationHeader
}

export const deduplicateArrayOfObjects = <Type>(arr: Type[], keys: string[]): Type[] =>
  arr.filter(
    (
      s => o =>
        (k => !s.has(k) && s.add(k))(keys.map(k => (o as any)[k]).join('|'))
    )(new Set()),
  )

export const getProtectpayStatusCodeMessage = (errorCode: string): string => {
  switch (errorCode) {
    case '00':
      return 'Success.'
    case '01':
      return 'Refer to card issuer'
    case '02':
      return 'Transaction denied. Please contact the issuing bank'
    case '03':
      return 'Invalid merchant'
    case '04':
      return 'Capture card'
    case '05':
      return 'Do not honor'
    case '06':
      return 'Customer requested stop of specific recurring payments'
    case '07':
      return 'Customer requested stop of all recurring payments'
    case '08':
      return 'Honor with ID'
    case '09':
      return 'Unpaid items'
    case '10':
      return 'Duplicate check number'
    case '11':
      return 'MICR error'
    case '12':
      return 'Invalid transaction'
    case '13':
      return 'Referral'
    case '14':
      return 'Invalid card number'
    case '15':
      return 'Invalid issuer'
    case '16':
      return 'You are trying to refund a card that has not been previously charged in this system.'
    case '17':
      return 'Amount greater than limit'
    case '18':
      return 'Too many checks (over merchant or bank limit)'
    case '19':
      return 'Reenter transaction'
    case '20':
      return 'Issuing bank unavailable'
    case '21':
      return 'Too many checks (over merchant or bank limit)'
    case '22':
      return 'Try again'
    case '23':
      return 'Void error'
    case '24':
      return 'Invalid expiration date'
    case '25':
      return 'Invalid terminal'
    case '26':
      return 'Credit error'
    case '27':
      return 'Fraud filter declined'
    case '28':
      return 'Fraud filter for review'
    case '29':
      return 'Issuing bank timeout'
    case '30':
      return 'Format error'
    case '41':
      return 'Lost card'
    case '43':
      return 'Stolen card'
    case '46':
      return 'Closed Account'
    case '51':
      return 'Insufficient funds/over credit limit'
    case '52':
      return 'No checking account'
    case '53':
      return 'Card cannot perform this kind of operation'
    case '54':
      return 'Expired card'
    case '55':
      return 'Invalid PIN'
    case '57':
      return 'Transaction not permitted to issuer/cardholder'
    case '58':
      return 'Transaction not permitted to acquirer/terminal'
    case '59':
      return 'Suspected Fraud'
    case '61':
      return 'Exceeds withdrawal limit'
    case '62':
      return 'Restricted card'
    case '63':
      return 'Security violation'
    case '65':
      return 'Exceeds withdrawal limit count'
    case '75':
      return 'Allowable number of PIN tries exceeded'
    case '76':
      return 'Invalid/nonexistent "To Account" specified'
    case '77':
      return 'Invalid/nonexistent "From Account" specified'
    case '78':
      return 'Invalid/nonexistent account specified (general)'
    case '80':
      return 'Invalid date'
    case '81':
      return 'Cryptography error'
    case '82':
      return 'CVV data is not correct'
    case '83':
      return 'Cannot verify the PIN'
    case '84':
      return 'Invalid authorization life cycle'
    case '85':
      return 'Not declined'
    case '86':
      return 'Gateway Timeout'
    case '93':
      return 'Violation cannot complete'
    case '94':
      return 'Duplicate transaction'
    case '96':
      return 'System Error'
    case '98':
      return 'Approval for a lesser amount'
    case '99':
      return 'Generic Decline (International Merchants) See ResponseMessage element for any additional detail'
    case '100':
      return 'Generic Decline'
    case '101':
      return 'Failed CVV Filter'
    case '102':
      return 'Failed AVS Filter'
    case '103':
      return 'Specified transaction in an invalid state for the requested operation'
    case '104':
      return 'Requested UserName not available'
    case '105':
      return 'AVS Address Mismatch'
    case '133':
      return 'Risk Decline'
    case '134':
      return 'Session Id is an invalid it should only contain upper and lowercase characters'
    case '135':
      return 'Nonexistent account configured for threat metrix on our system.'
    case '141':
      return 'Inactive or blocked MCC Code.'
    case '142':
      return 'Invalid MCC Code was entered that is either non numeric or does not exist in our database.'
    case '143':
      return '6P Customer data failed'
    case '199':
      return 'Misc. Decline'
    case '200':
      return 'Gateway authentication error'
    case '201':
      return 'Gateway invalid argument error'
    case '204':
      return 'Gateway account status error'
    case '206':
      return 'Gateway unsupported transaction request'
    case '207':
      return 'Gateway Internal system error'
    case '212':
      return 'Gateway Address validation error'
    case '214':
      return 'Gateway Invalid Destination Account'
    case '223':
      return 'Gateway Duplicate transaction'
    case '224':
      return 'Gateway Amount exceeds single transaction limit'
    case '225':
      return 'Gateway Amount exceeds monthly volume limit'
    case '226':
      return 'Gateway Invalid track 1'
    case '227':
      return 'Gateway reported decline based on user settings'
    case '230':
      return 'Unauthorized service requested on Gateway'
    case '236':
      return 'Capture amount exceeds allowed amount'
    case '237':
      return "MCC doesn't allow capturing for a greater amount"
    case '250':
      return 'CVV code no match'
    case '251':
      return 'Invalid Token'
    case '252':
      return 'Invalid Token Expiration Date'
    case '253':
      return 'Invalid token Requestor ID'
    case '254':
      return 'Both card and token should not be present'
    case '261':
      return 'Invalid external transaction ID'
    case '263':
      return 'Gateway Refund amount exceeds allowed amount'
    case '264':
      return 'Gateway Transaction has already been refunded'
    case '265':
      return 'Gateway reports insufficient funds to cover action in your merchant account'
    case '268':
      return 'Both TAVV and STVV should not be present'
    case '300':
      return 'Authentication error'
    case '301':
      return 'Invalid Argument error'
    case '302':
      return 'Invalid invoice number'
    case '303':
      return 'Gateway Timeout Error'
    case '304':
      return 'System of record account error'
    case '305':
      return 'Invalid track data'
    case '306':
      return 'Unsupported error'
    case '307':
      return 'Internal system error'
    case '308':
      return 'Invalid credit card'
    case '309':
      return 'Insufficient payment methods'
    case '310':
      return 'Unsupported currency code'
    case '311':
      return 'Invalid argument error'
    case '312':
      return 'Address validation error'
    case '313':
      return 'ID validation error'
    case '314':
      return 'Account validation error'
    case '315':
      return 'Payment Method validation error'
    case '316':
      return 'Call failed for an unspecified reason'
    case '317':
      return 'Duplicate Account Number Found'
    case '318':
      return 'Country code not supported'
    case '319':
      return 'Argument format error'
    case '320':
      return 'Argument required error'
    case '321':
      return 'Invalid password'
    case '322':
      return 'Latest EULA not signed'
    case '326':
      return 'Invalid track data'
    case '330':
      return 'Authorization Error'
    case '341':
      return 'Payment method does not exist'
    case '345':
      return 'Unable to process your request'
    case '346':
      return 'Not subscribed to AutoUpdater'
    case '347':
      return 'Not enrolled to auto update card brand'
    case '348':
      return 'Transaction successfully voided'
    case '349':
      return 'Transaction void failed'
    case '361':
      return 'Password expired'
    case '542':
      return 'Invalid receiving email'
    case '544':
      return 'Invalid amount'
    case '551':
      return 'Invalid trans num or unable to act due to funding'
    case '561':
      return 'Amount exceeds single transaction limit'
    case '562':
      return 'Amount exceeds monthly volume limit'
    case '567':
      return 'Unauthorized service requested'
    case '700':
      return 'Invalid payment method ID'
    default:
      console.info('unhandled error code: ', errorCode)
      return 'An error occurred. Please try again later.'
  }
}

export const getTypesenseNodeName = (nodeName: string) => {
  const nodes = nodeName.includes('.')
    ? [{ host: nodeName, port: 443, protocol: 'https' }]
    : [
        { host: `${nodeName}-1.a1.typesense.net`, port: 443, protocol: 'https' },
        { host: `${nodeName}-2.a1.typesense.net`, port: 443, protocol: 'https' },
        { host: `${nodeName}-3.a1.typesense.net`, port: 443, protocol: 'https' },
      ]
  const nearestNode = nodeName.includes('.')
    ? undefined
    : {
        host: `${nodeName}.a1.typesense.net`,
        port: 443,
        protocol: 'https',
      }

  return { nodes, nearestNode }
}
